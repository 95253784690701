export enum FlowSteps {
    NA = 'NA',
    BASE = 'BASE',
    USER_LOOKUP_RESULT = 'USER_LOOKUP_RESULT',
    CHARGE = 'CHARGE',
    CHARGE_CONFIRM = 'CHARGE_CONFIRM',
    CHARGE_RESULT = 'CHARGE_RESULT',
    LOYALTY_RESULT = 'LOYALTY_RESULT',
    PAYMENT_TENDERED = 'PAYMENT_TENDERED'
}
