// @ts-ignore
import soundFile from '../assets/beep.mp3';

class SoundService {
    private audio: HTMLAudioElement;
    private playCount: number;
    private repeatTimes: number;

    constructor() {
        this.audio = new Audio(soundFile);
        this.playCount = 0;
        this.repeatTimes = 0;
        this.audio.addEventListener('ended', this.replay.bind(this));
    }

    replay() {
        if (this.playCount < this.repeatTimes - 1) { // Check if the sound has been played less than n times
            this.playCount++;
            this.audio.currentTime = 0;
            this.audio.play();
        } else {
            this.playCount = 0; // Reset the counter
        }
    }

    play(times: number) {
        this.playCount = 0;
        this.repeatTimes = times;
        this.audio.play();
    }
}

export default new SoundService();
