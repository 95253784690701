import {FC, useCallback, useEffect, useRef, useState} from "react";
// @ts-ignore
import Webcam from 'react-webcam'
import {
    Button,
    Col,
    Modal,
    Progress,
    Row,
    Select,
    Spin,
    Typography,
    Dropdown,
    MenuProps
} from 'antd'
import {ExclamationCircleOutlined, CheckCircleOutlined, RedoOutlined, MoreOutlined} from '@ant-design/icons';
import {KeyboardReact} from "react-simple-keyboard";
import styles from './HomeComponent.module.css';
import "react-simple-keyboard/build/css/index.css";
import logo from './assets/yewpay_logo_negative_primary.png';
import glyph from './assets/yew_glyph_padded.png';
import {UserService} from "./services/UserService";
import SoundService from "./services/SoundService";
import {VideoConstraints} from "./videoConstants";
import ReconnectingWebSocket from 'reconnecting-websocket';
import {PaymentsService} from "./services/PaymentsService";
import {FlowSteps} from "./FlowSteps";
import { pico } from './pico-module.js';

interface HomeComponentProps {
    locations: string[];
}

const HomeComponent: FC<HomeComponentProps> = (props) => {
    const [locationCode, setLocationCode] = useState(props.locations[0] || '');
    const locationName = 'Ben Franks Restaurant'
    const [topStatus, setTopStatus] = useState('LOOKING FOR USER');
    const [showFlash, setShowFlash] = useState<any>(false);
    const lookupButtonRef = useRef<any>(null);
    const [capturedImage, setCapturedImage] = useState<any>(null);
    const [processedImage, setProcessedImage] = useState<any>(null);
    const canvasRef = useRef<any>(null);
    const [flowStep, setFlowStep] = useState<any>(FlowSteps.BASE);
    const [loadingLookup, setLoadingLookup] = useState<any>(false);
    const [lookupError, setLookupError] = useState<any>();
    const [lookupResult, setLookupResult] = useState<any>(null);
    const [lookupConfirmed, setLookupConfirmed] = useState<any>(false);
    const [foundFaces, setFoundFaces] = useState<any>(null);
    const [selectedFace, setSelectedFace] = useState<any>(null);
    const [user, setUser] = useState<any>(null);
    const currentSessionRef = useRef<any>(null);
    const [loyalty, setLoyalty] = useState<any>(null);
    const [loyaltyOnly, setLoyaltyOnly] = useState<any>(null);
    const [processPaymentLoading, setProcessPaymentLoading] = useState(false);
    const [chargeComplete, setChargeComplete] = useState<any>(false);
    const [paymentSuccessful, setPaymentSuccessful] = useState<any>(false);
    const [loyaltySuccessful, setLoyaltySuccessful] = useState<any>(false);
    const [paymentIntent, setPaymentIntent] = useState<any>(null);
    const paymentIntentRef = useRef(paymentIntent);
    const [paymentError, setPaymentError] = useState<any>(null);
    const [progressPercentage, setProgressPercentage] = useState<number>(0);
    const [confirmProgressPercentage, setConfirmProgressPercentage] = useState<number>(0);
    const confirmProgressIntervalId = useRef<any>(null);
    const progressIntervalId = useRef<any>(null);
    const waitTimeoutId = useRef<any>(null);
    const successRefreshSeconds = 15;
    const confirmRefreshSeconds = 60;
    const waitRefreshSeconds = 5;
    const webcamRef = useRef<Webcam | null>(null);
    const [webcamKey, setWebcamKey] = useState(Date.now());
    const [showWebcam, setShowWebcam] = useState(false);

    const pwsRef = useRef<ReconnectingWebSocket | null>(null);
    const lastMessageTimePWSRef = useRef<Date>(new Date());
    const [connectionsOk, setConnectionsOk] = useState(false);
    const keyboardChargeRef = useRef<any>();
    const [focusedChargeInput, setFocusedChargeInput] = useState('amount');
    const [chargeInputs, setChargeInputs] = useState<any>({});
    const [amountStr, setAmountStr] = useState<string>('$0.00');
    const topStatusColors: any = {
        'WAITING...': '#000',
        'LOOKING FOR USER': '#000',
        'LOOKING UP USER': '#000',
        'CUSTOMER TOO FAR AWAY': '#ff0000',
        'CUSTOMER TOO FAR LEFT': '#ff0000',
        'CUSTOMER TOO FAR RIGHT': '#ff0000',
        'CUSTOMER TOO FAR UP': '#ff0000',
        'CUSTOMER TOO FAR DOWN': '#ff0000',
        'USER FOUND': '#7ac968',
        'USERS FOUND': '#7ac968',
        'USER NOT FOUND': '#ff0000',
        'CARD ON FILE': '#7ac968',
        'NO CARD ON FILE': '#ff0000',
        'WAITING ON CONFIRMATION': '#000',
        'NOT PAID': '#ff0000',
        'PAID': '#7ac968',
        'LOYALTY ADDED': '#7ac968',
        'LOYALTY NOT ADDED': '#ff0000'
    }
    const keyboardChargeLayout = {
        'default': [
            '{enter}',
            '1 2 3',
            '4 5 6',
            '7 8 9',
            '{bksp} 0 00'
        ]
    }

    const keyboardButtonTheme = [
        {
            class: styles.keyboard_button_default,
            buttons: '1 2 3 4 5 6 7 8 9 0 00 {bksp}'
        },
        {
            class: styles.keyboard_button_action,
            buttons: '{enter}'
        }
    ]

    const keyboardButtonThemeDisabled = [
        {
            class: styles.keyboard_button_default,
            buttons: '1 2 3 4 5 6 7 8 9 0 00 {bksp}'
        },
        {
            class: styles.keyboard_button_action_disabled,
            buttons: '{enter}'
        }
    ]

    const dropdownItems: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <a onClick={() => {setShowWebcam(!showWebcam)}} rel={'noopener noreferrer'} >
                    <Typography style={{fontSize: '24px', fontWeight: 'bold', padding: '10px 20px 10px 20px'}}>{showWebcam ? 'Hide' : 'Show'} Display</Typography>
                </a>
            )
        },
    ]

    // Placeholder function for the classifier
    let facefinderClassifyRegion = (r: number, c: number, s: number, pixels: Uint8Array, ldim: number) => -1.0;
    const facefinderClassifyRegionRef = useRef(facefinderClassifyRegion);
    const detectionIntervalId = useRef<any>(null);
    const startedFaceDetection = useRef(false);
    const [faceDetectionTime, setFaceDetectionTime] = useState(0);


    useEffect(() => {
        const loadClassifier = async () => {
            const cascadeUrl = 'https://raw.githubusercontent.com/nenadmarkus/pico/master/rnt/cascades/facefinder';
            try {
                const response = await fetch(cascadeUrl);
                const buffer = await response.arrayBuffer();
                const bytes = new Int8Array(buffer);
                facefinderClassifyRegionRef.current = pico.unpack_cascade(bytes);
                console.log('* cascade loaded');
            } catch (error) {
                console.error('Failed to load the cascade', error);
            }
        };

        loadClassifier();
    }, []);

    useEffect(() => {
        startFaceDetection();
        return () => stopFaceDetection();
    }, []);


    useEffect(() => {
        paymentIntentRef.current = paymentIntent
        if (paymentIntent?.status == 'succeeded' || paymentIntent?.status == 'requires_capture') {
            console.log(`Payment Succeeded!`);
            setProcessPaymentLoading(false);
            handleChargeResult(true, null);
        } else if (paymentIntent?.status == 'requires_confirmation') {
            console.log(`Payment Waiting for Customer...`);
            handleChargeCreated()
        } else if (paymentIntent?.last_payment_error) {
            console.log(`Payment Error`);
            setProcessPaymentLoading(false);
            handleChargeResult(false, paymentIntent?.last_payment_error?.message);
        }
    }, [paymentIntent]);


    useEffect(() => {
        return refresh()
    }, [locationCode]);


    useEffect(() => {
        console.log(`FlowStep changed to ${flowStep}`)
        if (flowStep == FlowSteps.BASE) {
            startFaceDetection();
        }
    }, [flowStep])

    useEffect(() => {
        if (canvasRef.current) {
            canvasRef.current.addEventListener('click', handleCanvasClick)
            drawProcessedImage();
        }

        return () => {
            if (canvasRef.current) {
                canvasRef.current.removeEventListener('click', handleCanvasClick);
            }
        };
    }, [foundFaces]);

    useEffect(() => {
        if (selectedFace) {
            setUser(selectedFace['User'])
            setLoyalty(selectedFace['Loyalty'])
            drawProcessedImage();
        } else {
            setUser(null);
            setLoyalty(null);
        }

    }, [selectedFace])


    const drawProcessedImage = () => {
        if (canvasRef.current == null) return;
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const image = new Image();
        image.src = capturedImage;

        image.onload = () => {
            canvas.width = image.width;
            canvas.height = image.height;

            ctx.drawImage(image, 0, 0);
            ctx.filter = 'blur(10px)';
            ctx.drawImage(canvas, 0, 0);

            foundFaces.forEach((face: any) => {
                const { Left, Top, Width, Height } = face['Face']['BoundingBox'];
                const x = Left * image.width;
                const y = Top * image.height;
                const w = Width * image.width;
                const h = Height * image.height;

                // Calculate padding
                const paddingX = w * 0.10; // 20% of width
                const paddingY = h * 0.10; // 20% of height

                // Adjust x, y, width, and height for padding
                const paddedX = x - paddingX;
                const paddedY = y - paddingY;
                const paddedWidth = w + 2 * paddingX;
                const paddedHeight = h + 2 * paddingY;

                ctx.filter = 'none';
                ctx.drawImage(image, paddedX, paddedY, paddedWidth, paddedHeight, paddedX, paddedY, paddedWidth, paddedHeight);


                ctx.strokeStyle = face == selectedFace ? '#7ac968' : '#e5e4e2'; // Red color for the border
                ctx.lineWidth = 6;
                if (face != selectedFace) {
                    ctx.setLineDash([10, 10]); // Dashed line pattern
                }
                ctx.strokeRect(paddedX, paddedY, paddedWidth, paddedHeight);

            });
        }
    }

    const resetChargeState = () => {
        if (keyboardChargeRef?.current) {
            keyboardChargeRef.current.clearInput('amount');
            setAmountStr(formatAmount(null));
        }
    }

    const onKeyReleasedCharge = (button: any) => {
        if (button == '{enter}' && canCharge()) {
            if (+getChargeInputValue('amount') >= 10000) {
                Modal.confirm({
                    title: 'Are you sure you want to charge this amount?',
                    content: (<Typography>Charge this
                        customer <b>{formatAmount(getChargeInputValue('amount'))}</b></Typography>),
                    width: '100%',
                    onOk: () => {
                        charge();
                    },
                    onCancel: () => {
                        console.log('Charge canceled.');
                    },
                });
            } else {
                charge();
            }
        } else if (button == '{bksp}') {
            keyboardChargeRef.current.setCaretPosition(getChargeInputValue(focusedChargeInput).length)
            setAmountStr(formatAmount(getChargeInputValue('amount')));
        } else {
            setAmountStr(formatAmount(getChargeInputValue('amount')));
        }
    };

    const getChargeInputValue = (inputName: string) => {
        return chargeInputs[inputName] || "";
    };

    function formatAmount(amountString: any) {
        if (!amountString) {
            return '$0.00'
        }
        const amountInCents = parseInt(amountString, 10);
        const amountInDollars = amountInCents / 100;
        return `$${amountInDollars.toFixed(2)}`;
    }

    const onChangeAllCharge = (inputs: any) => {
        setChargeInputs({...inputs});
    };

    const startFaceDetection = () => {
        if (detectionIntervalId.current !== null) return;
        detectionIntervalId.current = setInterval(() => {
            handleFaceDetection();
        }, 1000);

        startedFaceDetection.current = true;
    }

    const stopFaceDetection = () => {
        if (detectionIntervalId.current !== null) {
            clearInterval(detectionIntervalId.current);
            detectionIntervalId.current = null;
        }
    }

    const handleFaceDetection = async () => {
        const time_start = Date.now();
        if (!webcamRef.current) return;

        // Get the screenshot from the webcam as a data URI
        const imageSrc = webcamRef.current.getScreenshot();
        if (!imageSrc) return;

        // Create a new image to load the screenshot
        const image = new Image();
        image.src = imageSrc;



        // Wait for the image to be loaded to ensure it has width and height
        await new Promise((resolve) => {
            image.onload = resolve;
        });

        // Convert the image to a canvas to extract pixel data
        const offscreenCanvas = document.createElement('canvas');
        offscreenCanvas.width = image.width;
        offscreenCanvas.height = image.height;
        const offscreenCtx = offscreenCanvas.getContext('2d');

        if (!offscreenCtx) return;

        // Draw the image onto the offscreen canvas
        offscreenCtx.drawImage(image, 0, 0, image.width, image.height);

        // Get the image data from the offscreen canvas
        const imageData = offscreenCtx.getImageData(0, 0, image.width, image.height);
        const pixels = rgbaToGrayscale(imageData.data, image.height, image.width);

        // Define the image and parameters for detection
        const picoImage = {
            "pixels": pixels,
            "nrows": image.height,
            "ncols": image.width,
            "ldim": image.width
        };

        const params = {
            "shiftfactor": 0.1, // move the detection window by 10% of its size
            "minsize": 100,     // minimum size of a face
            "maxsize": 1000,    // maximum size of a face
            "scalefactor": 1.1  // for multiscale processing: resize the detection window by 10% when moving to the higher scale
        };

        // Run the face detection
        const dets = pico.run_cascade(picoImage, facefinderClassifyRegionRef.current, params);
        const detections = pico.cluster_detections(dets, 0.2); // Use a clustering threshold


        const processingTime = Date.now() - time_start;
        setFaceDetectionTime(processingTime);
        console.log(`Found ${detections.length} faces in ${processingTime}ms`);

        if (detections.length > 0) {
            handleFaceDetectionResults(detections, image.width, image.height);
        } else {
            setTopStatus('LOOKING FOR USER');
        }

    }

    const handleFaceDetectionResults = (detections: any, imgW: any, imgH: any) => {
        // Only look at the largest face
        const largestDetection = detections.reduce((max: any, det: any) => {
            return (det[2] * det[2] > max[2] * max[2]) ? det : max;
        }, detections[0]);

        const centerY = largestDetection[0];
        const centerX = largestDetection[1];
        const w = largestDetection[2];

        const imgCenterX = imgW / 2;
        const imgCenterY = imgH / 2;

        const deltaX = centerX - imgCenterX;
        const deltaY = centerY - imgCenterY;

        const deltaThresholdX = Math.round(imgW * 0.35);
        const deltaThresholdY = Math.round(imgH * 0.35);
        const sizeThreshold = Math.min(Math.round(imgW * .15), Math.round(imgH * .15))

        if (w < sizeThreshold) {
            setTopStatus('CUSTOMER TOO FAR AWAY');
        } else if (Math.abs(deltaX) > deltaThresholdX || Math.abs(deltaY) > deltaThresholdY) {
            if (Math.abs(deltaX) > deltaThresholdX) {
                if (deltaX > 0) {
                    setTopStatus('CUSTOMER TOO FAR LEFT')
                } else {
                    setTopStatus('CUSTOMER TOO FAR RIGHT')
                }
            } else {
                if (deltaY > 0) {
                    setTopStatus('CUSTOMER TOO FAR DOWN')
                } else {
                    setTopStatus('CUSTOMER TOO FAR UP')
                }
            }
        } else {
            stopFaceDetection();
            setFlowStep(FlowSteps.NA);
            startLookup(null, true);
        }
    }

    const rgbaToGrayscale = (rgba: Uint8ClampedArray, nrows: number, ncols: number): Uint8Array => {
        const gray = new Uint8Array(nrows * ncols);
        for (let r = 0; r < nrows; r++) {
            for (let c = 0; c < ncols; c++) {
                gray[r * ncols + c] = (2 * rgba[r * 4 * ncols + 4 * c] + 7 * rgba[r * 4 * ncols + 4 * c + 1] + rgba[r * 4 * ncols + 4 * c + 2]) / 10;
            }
        }
        return gray;
    };

    const lookupUsers = (imageSrc: any, passive: boolean=false) => {
        // TODO alter this function to not show anything if a user is not found
        setLoadingLookup(true);
        setTopStatus('LOOKING UP USER');
        UserService.lookupFaces(JSON.stringify({
            'image': imageSrc,
            'location_code': locationCode
        }), setLoadingLookup).subscribe((data: any) => {
            console.log(`Data: ${JSON.stringify(data, null, 2)}`)
            if (data?.status == 400 || data?.faces?.length == 0) {
                if (!passive) {
                    setCapturedImage(imageSrc);
                    handleLookupResult(false);
                    setLoadingLookup(false);
                    setLookupError(data?.statusText);
                } else {
                    refresh();
                }
            } else {
                setCapturedImage(imageSrc);
                SoundService.play(1);
                setFoundFaces(data?.faces);
                if (data?.faces?.length == 1) {
                    setSelectedFace(data?.faces[0])
                }
                console.log(`Setting currentSessionId: ${data?.session?.session_id}`)
                currentSessionRef.current = data?.session?.session_id
                handleLookupResult(true);
            }
        }, (error: any) => {
            console.error(`Error verifying face: ${error}`);
            if (!passive) {
                setCapturedImage(imageSrc);
                handleLookupResult(false);
                setLoadingLookup(false);
            } else {
                // Pause for a bit so we don't keep looking up the same person
                setTopStatus('WAITING...')
                const waitTimeout = setTimeout(() => {
                    refresh();
                }, waitRefreshSeconds*1000);

                waitTimeoutId.current = waitTimeout
            }
        })
    }

    const handleLookupResult = (success: boolean) => {
        console.log(`Lookup result: ${success}`)
        setFlowStep(FlowSteps.USER_LOOKUP_RESULT);
        setLookupResult(success ? 'USER_FOUND' : 'NOT_FOUND')
        setTopStatus(success ? (foundFaces?.length > 1 ? 'USERS FOUND' : 'USER FOUND') : 'USER NOT FOUND')
        let elapsed = 0;
        setConfirmProgressPercentage(100);

        const intervalId = setInterval(() => {
            elapsed += 100; // 100ms
            const percentage = 100 - (elapsed / (confirmRefreshSeconds*1000)) * 100;
            setConfirmProgressPercentage(percentage);

            if (percentage <= 0) {
                clearInterval(intervalId);
                refresh();
            }
        }, 100);

        confirmProgressIntervalId.current = intervalId  // Store the interval ID
    }


    const startLookup = (e: any, passive: boolean=false) => {
        if (!lookupButtonRef.current) return;

        if (webcamRef.current) {
            const imageSrc = webcamRef.current.getScreenshot()
            if (!passive) {
                setShowFlash(true);
                setTimeout(() => {
                    setShowFlash(false);
                }, 200);
                setCapturedImage(imageSrc);
            }
            lookupUsers(imageSrc, passive);
        }
    }

    const handleCanvasClick = (event: any) => {
        const rect = canvasRef.current.getBoundingClientRect();
        const scaleX = canvasRef.current.width / rect.width;
        const scaleY = canvasRef.current.height / rect.height;
        const x = (event.clientX - rect.left) * scaleX;
        const y = (event.clientY - rect.top) * scaleY;

        const clickedFace = foundFaces.find((face: any) => {
            const { Left, Top, Width, Height } = face['Face']['BoundingBox'];
            const faceX = Left * canvasRef.current.width;
            const faceY = Top * canvasRef.current.height;
            const faceWidth = Width * canvasRef.current.width;
            const faceHeight = Height * canvasRef.current.height;

            return x >= faceX && x <= faceX + faceWidth && y >= faceY && y <= faceY + faceHeight;
        });

        if (clickedFace) {
            setSelectedFace(clickedFace);
        }
    };

    const confirmLookup = (loyaltyOnly: boolean) => {
        if (confirmProgressIntervalId.current) clearInterval(confirmProgressIntervalId.current);
        setLookupConfirmed(true);
        setLoyaltyOnly(loyaltyOnly);
        setTopStatus( user?.payment_methods_available ? 'CARD ON FILE' : 'NO CARD ON FILE');
        setFlowStep(FlowSteps.CHARGE);
    }


    function canCharge() {
        return !processPaymentLoading && +getChargeInputValue('amount') >= 50 && +getChargeInputValue('amount') < 50000
    }

    const charge = () => {
        setProcessPaymentLoading(true);
        const details = {
            'amount': getChargeInputValue('amount'),
            'location_code': locationCode,
            'location_name': locationName,
            'user_id': user?.id,
            'session_id': currentSessionRef.current
        }
        console.log(`Charging ${details.amount} to location ${details.location_code}, user ${user?.id}, session ${currentSessionRef.current}`);
        if (user?.payment_methods_available && !loyaltyOnly) {
            PaymentsService.processFacePayment(JSON.stringify(details), null).subscribe(
                (data: any) => {
                    console.log(`Process Face Payment Response: ${JSON.stringify(data)}`);
                    console.log(`Setting PaymentIntent ${data?.id}`)
                    setPaymentIntent(data);
                },
                (error: any) => {
                    console.error(`Error processing face payment: ${error}`);
                    handleChargeResult(false, 'Payment Error - Try Again Later')
                }
            );
        } else {
            PaymentsService.processFaceLoyalty(JSON.stringify(details), setProcessPaymentLoading).subscribe(
                (data: any) => {
                    console.log(`Process Face Loyalty Response: ${JSON.stringify(data)}`);
                    handleLoyaltyResult(true);
                },
                (error: any) => {
                    console.error(`Error processing face loyalty: ${error}`);
                    setProcessPaymentLoading(false);
                    handleLoyaltyResult(false);
                }
            );
        }
    }

    const handleChargeCreated = () => {
        setFlowStep(FlowSteps.CHARGE_CONFIRM);
        setTopStatus('WAITING ON CONFIRMATION')
    }

    const handleChargeResult = (success: boolean, error: any) => {
        setPaymentSuccessful(success);
        setChargeComplete(true);
        setFlowStep(FlowSteps.CHARGE_RESULT);

        if (success) {
            setTopStatus( 'PAID')
            SoundService.play(3);
            let elapsed = 0;
            setProgressPercentage(100);

            const intervalId = setInterval(() => {
                elapsed += 100; // 100ms
                const percentage = 100 - (elapsed / (successRefreshSeconds*1000)) * 100;
                setProgressPercentage(percentage);

                if (percentage <= 0) {
                    clearInterval(intervalId);
                    refresh();
                }
            }, 100);

            progressIntervalId.current = intervalId  // Store the interval ID
        } else {
            setTopStatus('NOT PAID')
            setPaymentError(error);
            let elapsed = 0;
            setProgressPercentage(100);

            const intervalId = setInterval(() => {
                elapsed += 100; // 100ms
                const percentage = 100 - (elapsed / (successRefreshSeconds*1000)) * 100;
                setProgressPercentage(percentage);

                if (percentage <= 0) {
                    clearInterval(intervalId);
                    refresh();
                }
            }, 100);

            progressIntervalId.current = intervalId  // Store the interval ID
        }
    }

    function handleLoyaltyResult(success: boolean) {
        setLoyaltySuccessful(success);
        setTopStatus(success ? 'LOYALTY ADDED' : 'LOYALTY NOT ADDED');
        setFlowStep(FlowSteps.LOYALTY_RESULT);
    }

    function acknowledgePaymentTaken() {
        setFlowStep(FlowSteps.PAYMENT_TENDERED);
        let elapsed = 0;
        setProgressPercentage(100);

        const intervalId = setInterval(() => {
            elapsed += 100; // 100ms
            const percentage = 100 - (elapsed / (successRefreshSeconds*1000)) * 100;
            setProgressPercentage(percentage);

            if (percentage <= 0) {
                clearInterval(intervalId);
                refresh();
            }
        }, 100);

        progressIntervalId.current = intervalId  // Store the interval ID
    }


    function refresh() {
        console.log(`Refreshing...`)
        if (progressIntervalId.current) clearInterval(progressIntervalId.current);
        if (confirmProgressIntervalId.current) clearInterval(confirmProgressIntervalId.current);
        if (waitTimeoutId.current) clearTimeout(waitTimeoutId.current);

        closePwsConnection()
        openPwsConnection()

        setCapturedImage(null);
        setLoadingLookup(false);
        setLookupResult(null);
        setLookupError(null);
        setUser(null);
        setSelectedFace(null);
        setFoundFaces(null);
        currentSessionRef.current = null;
        setLookupConfirmed(false);
        setLoyaltyOnly(false);
        setChargeComplete(false);
        setPaymentSuccessful(false);
        setLoyalty(null);

        setTopStatus('LOOKING FOR USER');

        resetChargeState();

        setFlowStep(FlowSteps.BASE);


        // create an interval that checks the last message time and refreshes connection if needed
        const checkInterval = setInterval(() => {
            const now = new Date();
            // Check the payments WS
            const diffInMinutesPWS = (now.getTime() - lastMessageTimePWSRef.current.getTime()) / (1000 * 60);

            if (diffInMinutesPWS >= 5) { // adjust time as needed
                if (pwsRef.current) {
                    pwsRef.current.reconnect(1000, 'Manual reconnect');
                    lastMessageTimePWSRef.current = new Date();
                }
            }

            setConnectionsOk(pwsRef.current != null && pwsRef.current.readyState === WebSocket.OPEN);
        }, 1000 * 5); // check every 5 seconds

        return () => {
            if (progressIntervalId.current) clearInterval(progressIntervalId.current);
            if (checkInterval) clearInterval(checkInterval);
            stopFaceDetection();
            closePwsConnection()
            setConnectionsOk(false);
        };
    }

    const openPwsConnection = () => {
        const pws = new ReconnectingWebSocket(`wss://eyjpur1ao7.execute-api.us-west-2.amazonaws.com/production?location_code=${locationCode}&product=FACE`);
        pwsRef.current = pws;

        pws.addEventListener('message', (message) => {
            const data = JSON.parse(message.data);
            process_payment_message(data)
        });

        // Update the connection status when the websocket is opened or closed
        pws.addEventListener('open', () => setConnectionsOk(pwsRef.current !== null));
        pws.addEventListener('close', () => setConnectionsOk(pwsRef.current !== null));
        pws.addEventListener('error', (error) => {
            // Handle error here
            console.log('Payment WebSocket error:', error);
            setConnectionsOk(false);
        });
    };

    const closePwsConnection = () => {
        if (pwsRef.current) {
            pwsRef.current.close();
            pwsRef.current = null;
        }
    };


    const process_payment_message = async (data: any) => {
        if (data?.keep_alive) {
            lastMessageTimePWSRef.current = new Date();
            return
        }
        console.log(`Received payment message: ${JSON.stringify(data)}`)
        lastMessageTimePWSRef.current = new Date();
        const paymentIntentObj = data?.data?.object || data
        console.log(`Received PaymentIntent ${paymentIntentObj?.metadata?.session_id} vs ${currentSessionRef.current}`)
        if (paymentIntentObj?.object == 'payment_intent' && paymentIntentObj?.metadata?.session_id == currentSessionRef.current) {
            // Make sure the new PaymentIntent is newer
            if (paymentIntentObj?.created > paymentIntentRef?.current?.created || paymentIntentObj?.status == 'succeeded' || paymentIntentObj?.status == 'requires_capture' || paymentIntentObj?.status == 'requires_confirmation') {
                setPaymentIntent(paymentIntentObj);
                console.log(`Received a status update for the recent processed payment: ${paymentIntentObj?.status}`)
            } else {
                console.log(`Unable to update PaymentIntent: ${paymentIntentObj?.created} vs ${paymentIntentRef?.current?.created}`)
            }
        }
    }

    function getTimeLeft(percentProgress: any, maxTime: any) {
        const secondsLeft: any = (100 - percentProgress) / maxTime
        return `${secondsLeft.toFixed(1)}s`
    }

    return (
        <div style={{height: '100%', maxHeight: '100%', display: 'flex', flexDirection: 'column', width: '95%'}}>
            <Row style={{
                flex: '0 0 10%',
                maxHeight: '10%',
                overflow: 'auto',
                paddingTop: '10px',
                paddingBottom: '10px'
            }}>
                <Col span={24} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <Row style={{width: '100%'}}>
                        <Col span={3} style={{display: 'flex', justifyContent: 'left'}}>
                            <Dropdown menu={{items: dropdownItems}}>
                                <MoreOutlined style={{ fontSize: 24, paddingLeft: '10px', paddingRight: '30px', color: '#374151' }} />
                            </Dropdown>
                        </Col>
                        <Col span={18} style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                            <Typography.Text style={{fontSize: '24px', fontWeight: '600', color: topStatusColors[topStatus]}}>
                                {topStatus}
                            </Typography.Text>
                            {lookupResult && !user && lookupError &&
                                <Typography.Text style={{fontSize: '24px', fontWeight: '600', color: '#eb1c24'}}>
                                    {lookupError}
                                </Typography.Text>
                            }
                        </Col>
                        <Col span={3} style={{display: 'flex', justifyContent: 'right', alignItems: 'center'}}>
                            <Typography style={{marginRight: '4px'}}>{faceDetectionTime}ms</Typography>
                            <img src={glyph} alt="Glyph" style={{ height: '30px' }} />
                        </Col>
                    </Row>
                    <div style={{flexGrow: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%'}}>
                        {false && props?.locations?.length > 1 &&
                            <Select
                                defaultValue={locationCode}
                                style={{width: '100%'}}
                                onChange={(value: any) => setLocationCode(value)}
                            >
                                {
                                    props.locations.map((location) =>
                                        <Select.Option value={location} key={location}>{location}</Select.Option>
                                    )
                                }
                            </Select>
                        }
                    </div>
                </Col>
            </Row>
            <Row style={{
                flex: '0 0 35%',
                maxHeight: '35%',
                overflow: 'hidden',
                paddingTop: '10px',
                paddingBottom: '10px'
            }}>
                <Col span={24} style={{height: '100%', width: '100%', maxHeight: '100%', overflow: 'hidden'}}>
                    <div style={{backgroundColor: '#fff', width: '100%', height: '100%', maxHeight: '100%', position: 'relative', overflow: 'hidden', borderRadius: '8px'}}>
                        {capturedImage ? (
                            <div style={{position: 'relative', maxHeight: '100%', width: '100%', height: '100%', overflow: 'hidden'}}>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        backgroundColor: showFlash ? 'rgba(255, 255, 255, 0.9)' : 'transparent',
                                        transition: 'background-color 0.3s ease-out'
                                    }}
                                />
                                <canvas ref={canvasRef} style={{position: 'absolute',
                                    top: '25%',
                                    left: '25%',
                                    transform: 'translate(-25%, -25%)',
                                    width: '100%',
                                    objectFit: 'cover',
                                    borderRadius: '8px'}}/>
                                {/*<img src={capturedImage} alt="Captured"*/}
                                {/*     style={{position: 'absolute',*/}
                                {/*         top: '25%',*/}
                                {/*         left: '25%',*/}
                                {/*         transform: 'translate(-25%, -25%)',*/}
                                {/*         width: '100%',*/}
                                {/*         objectFit: 'cover',*/}
                                {/*         borderRadius: '8px'}}/>*/}
                                {(loadingLookup || processPaymentLoading) && (
                                    <div style={{
                                        position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
                                        backgroundColor: 'rgba(128, 128, 128, 0.5)', display: 'flex',
                                        justifyContent: 'center', alignItems: 'center'
                                    }}>
                                        <Spin/>
                                    </div>
                                )}
                                {!(loadingLookup || processPaymentLoading) &&
                                    <div style={{position: 'absolute', right: '20px', top: '20px'}}>
                                        <Button type="primary" shape="circle" icon={<RedoOutlined/>} size={'large'}
                                                onClick={() => refresh()}/>
                                    </div>
                                }
                                <div style={{ position: 'absolute', bottom: '10px', left: '10px', zIndex: 10 }}>
                                    <img src={logo} alt="Logo" style={{ height: '20px' }} />
                                </div>
                            </div>
                        ) : (
                            <div style={{position: 'relative', maxHeight: '100%', width: '100%', height: '100%', overflow: 'hidden'}}>
                                <div style={{ position: 'absolute', top: '2px', left: '2px', width: 'calc(100% - 4px)', overflow: 'hidden', height: 'calc(100% - 4px)', zIndex: showWebcam ? 10 : 1}}>
                                    <Webcam
                                        audio={false}
                                        screenshotFormat="image/jpeg"
                                        ref={webcamRef}
                                        key={webcamKey}
                                        style={{ position: 'absolute',
                                            top: '12.5%',
                                            left: '25%',
                                            transform: 'translate(-25%, -25%)',
                                            width: '100%',
                                            objectFit: 'cover',
                                            borderRadius: '8px'
                                        }}
                                        videoConstraints={VideoConstraints}
                                        onUserMediaError={(error: any) => {
                                            console.error('Webcam Error: ', error)
                                        }}
                                    />
                                </div>
                                <div style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 2, background: '#fbfcff' }}>
                                    <img className={styles.pulse} src={glyph} alt="Glyph" style={{ height: '200px'}} />
                                </div>
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
            <Row style={{
                flex: '0 0 55%',
                maxHeight: '55%',
                overflow: 'auto',
                paddingTop: '10px',
                paddingBottom: '10px'}}>
                <Col span={24}>
                    {(flowStep == FlowSteps.BASE || flowStep == FlowSteps.NA) &&
                        <Button ref={lookupButtonRef} style={{fontSize: '32px', height: '86px', fontWeight: '600'}} type={"primary"} block
                                onClick={startLookup} loading={loadingLookup} >Lookup User</Button>
                    }
                    {flowStep == FlowSteps.USER_LOOKUP_RESULT && lookupResult == 'NOT_FOUND' &&
                        <Button ref={lookupButtonRef}
                                style={{fontSize: '32px', height: '86px', fontWeight: '600'}} type={"primary"} block
                                onClick={refresh} loading={loadingLookup}>
                            Try Again
                            <Progress
                                type={'circle'}
                                percent={confirmProgressPercentage}
                                format={(progressPercentage) => getTimeLeft(confirmProgressPercentage, confirmRefreshSeconds)}
                                strokeColor={'white'}
                                size={25}
                                showInfo={false}
                                style={{
                                    position: 'absolute', // Set the position to absolute
                                    right: '20px',        // Position it 20px from the right
                                    top: '50%',           // Start from the middle vertically
                                    transform: 'translateY(-50%)'  // Adjust to perfectly center vertically
                                }}
                            />
                        </Button>

                    }
                    {flowStep == FlowSteps.USER_LOOKUP_RESULT && lookupResult == 'USER_FOUND' && !lookupConfirmed &&
                        <div style={{width: '100%'}}>
                            <Button ref={lookupButtonRef}
                                    style={{
                                        fontSize: '32px',
                                        height: '86px',
                                        fontWeight: '600',
                                        position: 'relative', // Set the position to relative
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'  // This ensures that the text stays centered
                                    }}
                                    type={"primary"}
                                    block
                                    disabled={!user?.payment_methods_available}
                                    onClick={() => confirmLookup(false)} loading={loadingLookup}>
                                Charge + Loyalty
                                <Progress
                                    type={'circle'}
                                    percent={confirmProgressPercentage}
                                    format={(progressPercentage) => getTimeLeft(confirmProgressPercentage, confirmRefreshSeconds)}
                                    strokeColor={'white'}
                                    size={25}
                                    showInfo={false}
                                    style={{
                                        position: 'absolute', // Set the position to absolute
                                        right: '20px',        // Position it 20px from the right
                                        top: '50%',           // Start from the middle vertically
                                        transform: 'translateY(-50%)'  // Adjust to perfectly center vertically
                                    }}
                                />
                            </Button>
                            <Button style={{
                                        fontSize: '32px',
                                        height: '86px',
                                        fontWeight: '600',
                                        position: 'relative', // Set the position to relative
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',  // This ensures that the text stays centered
                                        marginTop: '20px'
                                    }}
                                    block
                                    disabled={!user}
                                    onClick={() => confirmLookup(true)} loading={loadingLookup}>
                                Loyalty Only
                            </Button>
                            {!selectedFace &&
                                <div style={{marginTop: '40px'}}>
                                    <Typography.Text style={{fontSize: '32px', fontWeight: '600'}}>
                                        Tap On The Paying Customer
                                    </Typography.Text>
                                </div>
                            }
                            {selectedFace && !user?.payment_methods_available &&
                                <div style={{marginTop: '40px'}}>
                                    <Typography.Text style={{fontSize: '32px', fontWeight: '600', color: 'red'}}>
                                        No Valid Payment Method Found
                                    </Typography.Text>
                                </div>
                            }
                        </div>
                    }
                    {(flowStep == FlowSteps.CHARGE || flowStep == FlowSteps.CHARGE_CONFIRM) && !chargeComplete &&
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {loyalty?.unactivated_loyalty_redemptions_available &&
                                <Typography.Text style={{fontSize: '24px', fontWeight: '600', marginBottom: '10px'}}>
                                    Rewards Not Activated
                                </Typography.Text>
                            }
                            <KeyboardReact
                                keyboardRef={r => (keyboardChargeRef.current = r)}
                                layout={keyboardChargeLayout} display={{
                                '{bksp}': '<',
                                '{enter}': `${(user?.payment_methods_available && !loyaltyOnly) ? 'Charge' : 'Loyalty For'} ${amountStr}`
                            }}
                                buttonTheme={canCharge() ? keyboardButtonTheme : keyboardButtonThemeDisabled}
                                onKeyReleased={onKeyReleasedCharge}
                                inputName={focusedChargeInput}
                                onChangeAll={onChangeAllCharge}
                            />
                        </div>
                    }
                    {flowStep == FlowSteps.CHARGE_RESULT && paymentSuccessful &&
                        <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                            <Button
                                style={{
                                    fontSize: '32px',
                                    height: '86px',
                                    fontWeight: '600',
                                    position: 'relative', // Set the position to relative
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'  // This ensures that the text stays centered
                                }}
                                type={"primary"}
                                block
                                onClick={() => refresh()}
                            >
                                New Customer
                                <Progress
                                    type={'circle'}
                                    percent={progressPercentage}
                                    format={(progressPercentage) => getTimeLeft(progressPercentage, successRefreshSeconds)}
                                    strokeColor={'white'}
                                    size={25}
                                    showInfo={false}
                                    style={{
                                        position: 'absolute', // Set the position to absolute
                                        right: '20px',        // Position it 20px from the right
                                        top: '50%',           // Start from the middle vertically
                                        transform: 'translateY(-50%)'  // Adjust to perfectly center vertically
                                    }}
                                />
                            </Button>
                            <div style={{
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                textAlign: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: '100%'
                            }}>
                                <Typography.Text style={{fontSize: '30px', fontWeight: 'bold', color: '#7ac968'}}>
                                    PAID
                                </Typography.Text>
                                <CheckCircleOutlined style={{color: '#7ac968', fontSize: '48px', marginTop: '20px', paddingBottom: '20px'}}/>
                            </div>
                        </div>

                    }
                    {flowStep == FlowSteps.CHARGE_RESULT && !paymentSuccessful &&
                        <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                            <Button
                                style={{
                                    fontSize: '32px',
                                    height: '86px',
                                    fontWeight: '600',
                                    position: 'relative', // Set the position to relative
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'  // This ensures that the text stays centered
                                }}
                                type={"primary"}
                                block
                                onClick={() => acknowledgePaymentTaken()}
                            >
                                PAYMENT TAKEN
                            </Button>
                            <div style={{
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                textAlign: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: '100%'
                            }}>
                                <Typography.Text style={{fontSize: '30px', fontWeight: 'bold', color: 'red'}}>
                                    NOT PAID
                                </Typography.Text>
                                <ExclamationCircleOutlined style={{color: 'red', fontSize: '48px', marginTop: '20px', paddingBottom: '20px'}}/>
                                <Typography.Text style={{fontSize: '14px', fontWeight: 'bold', color: 'red'}}>
                                    {paymentError}
                                </Typography.Text>
                            </div>
                        </div>
                    }
                    {flowStep == FlowSteps.LOYALTY_RESULT &&
                        <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                            <Button
                                style={{
                                    fontSize: '32px',
                                    height: '86px',
                                    fontWeight: '600',
                                    position: 'relative', // Set the position to relative
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'  // This ensures that the text stays centered
                                }}
                                type={"primary"}
                                block
                                onClick={() => acknowledgePaymentTaken()}
                                loading={processPaymentLoading}
                            >
                                PAYMENT TAKEN
                            </Button>
                            <div style={{
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                textAlign: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: '100%'
                            }}>
                                <Typography.Text style={{fontSize: '30px', fontWeight: 'bold', color: 'red'}}>
                                    NOT PAID
                                </Typography.Text>
                                <ExclamationCircleOutlined style={{color: 'red', fontSize: '48px', marginTop: '20px', paddingBottom: '20px'}}/>
                                {loyaltySuccessful ? (
                                        <Typography.Text style={{fontSize: '18px', fontWeight: 'bold'}}>
                                            Loyalty Added
                                        </Typography.Text>
                                    ) :
                                    (
                                        <Typography.Text style={{fontSize: '18px', fontWeight: 'red'}}>
                                            Loyalty NOT Added
                                        </Typography.Text>
                                    )
                                }
                            </div>
                        </div>
                    }
                    {flowStep == FlowSteps.PAYMENT_TENDERED &&
                        <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                            <Button
                                style={{
                                    fontSize: '32px',
                                    height: '86px',
                                    fontWeight: '600',
                                    position: 'relative', // Set the position to relative
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'  // This ensures that the text stays centered
                                }}
                                type={"primary"}
                                block
                                onClick={() => refresh()}
                                loading={processPaymentLoading}
                            >
                                New Customer
                                <Progress
                                    type={'circle'}
                                    percent={progressPercentage}
                                    format={(progressPercentage) => getTimeLeft(progressPercentage, successRefreshSeconds)}
                                    strokeColor={'white'}
                                    size={25}
                                    showInfo={false}
                                    style={{
                                        position: 'absolute', // Set the position to absolute
                                        right: '20px',        // Position it 20px from the right
                                        top: '50%',           // Start from the middle vertically
                                        transform: 'translateY(-50%)'  // Adjust to perfectly center vertically
                                    }}
                                />
                            </Button>
                        </div>
                    }
                </Col>
            </Row>
        </div>
    )
        ;

}

export default HomeComponent;