import GeneralService from "./GeneralService";
import { Component } from "react";

const ENDPOINT = "https://api.yewfi.com"
// const ENDPOINT = "http://localhost:5000"

export class PaymentsService extends Component {

    public static processFacePayment(body: string, loadingHandler: any) {
        return GeneralService.post('/payments/process_face_payment', body, null, loadingHandler, JSON, ENDPOINT);
    }

    public static processFaceLoyalty(body: string, loadingHandler: any) {
        return GeneralService.post('/payments/process_face_loyalty', body, null, loadingHandler, JSON, ENDPOINT);
    }

}